import React from "react";
import { Pager } from "react-bootstrap";

import Navbar from "./Navbar";
import ReactPageScroller from "../../src";

import Home from "./pages/home/Home";
import Products from "./pages/products/Products";
import Vendors from "./pages/vendors/Vendors";
import ContactUs from "./pages/contactus/ContactUs";

import "./styles/index.css";

export default class FullPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      LogoCol: null,
      buttonClass: "primary",
      animator: {
        Slide1: null,
        Slide2: null
      }
    };
  }

  handlePageChange = number => {
    if (number == 0 || 3) {
      this.setState({ LogoCol: "primaryNav", buttonClass: "primary", animator: { Slide1: false } });
    }
    if (number == 1) {
      this.setState({ LogoCol: "blueNav", buttonClass: "blue", animator: { Slide1: true, Slide2: false } });
    }
    if (number == 2) {
      this.setState({ LogoCol: "purpleNav", buttonClass: "purple", animator: { Slide2: true, Slide1: false } });
    }
    this.setState({ currentPage: number });
  };

  handleBeforePageChange = number => {
    console.log(number);
  };

  render() {
    return (
      <React.Fragment>
        <ReactPageScroller
          pageOnChange={this.handlePageChange}
          onBeforePageScroll={this.handleBeforePageChange}
          customPageNumber={this.state.currentPage}
        >
          <Home handlePageChange={this.handlePageChange} />
          <Products Animator={this.state.animator} />
          <Vendors Animator={this.state.animator} />
          <ContactUs />
        </ReactPageScroller>
        <Pager>
          {/*pagesNumbers*/}
          <Navbar handlePageChange={this.handlePageChange} LogoCol={this.state.LogoCol} buttonClass={this.state.buttonClass} />
        </Pager>
      </React.Fragment>
    );
  }
}
