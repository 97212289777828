import React from "react";
import "./Products.css";
import { Slide } from '@material-ui/core';

function ProductSubCol({
  firstImage,
  secondImage,
  firstProductText,
  secondProductText,
  Animator,
  Timeout
}) {
  return (
    <Slide direction="left" in={Animator.Slide1} timeout={Timeout} mountOnEnter unmountOnExit>
      <div
        className="row home__hero-row"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="product-subcol-new">
          <div className="product-box">
            <div>
              <img className="product-image" src={firstImage} />
            </div>
            <div className="product-text">{firstProductText}</div>
          </div>
        </div>
        <div className="product-subcol-new">
          <div className="product-box">
            <div>
              <img className="product-image" src={secondImage} />
            </div>
            <div className="product-text">{secondProductText}</div>
          </div>
        </div>
      </div>
    </Slide>
  );
}

export default ProductSubCol;
