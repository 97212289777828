import React, { useLayoutEffect, useState } from "react";
import "./Vendors.css";
import VendorsSubCol from "./VendorsSubCol";
import Hyundai from "./../../Logo/hyundai.svg";
import ITC from "./../../Logo/itc_limited.svg";
import Kavika from "./../../Logo/kavika.svg";
import SurfaCoats from "./../../Logo/surfa_coats.svg";
import UL from "../../StyleSVGs/vendorsul.svg";
import Palette from "../../StyleSVGs/palette.svg"

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function Vendors(props) {
  const { Animator } = props
  const [width, height] = useWindowSize();
  return (
    <>
      {" "}
      <div
        className={"home__hero-section vendors-darkBg"}
        style={{ height: height }}
      >
        <div className="container-new" style={{ height: height }}>
          <div
            className="row-new home__hero-row"
            style={{
              display: "flex",
              flexDirection: "row",
              height: height,
            }}
          >
            <div className="vendor-col-new one ">
              <div className="vendor__hero-text-wrapper">
                <h1 className={"vendor-heading"}>Our Vendors</h1>
                <img src={UL} />
                <p className={"vendor__hero-subtitle"}>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet.
                </p>
                <p className={"vendor__hero-subtitle"}><img src={Palette} /></p>
              </div>
            </div>
            <div className="vendor-col-new  two">
              <div className="home__hero-img-wrapper">
                <VendorsSubCol Animator={Animator} Timeout={{ enter: 800,exit: 1000 }}vendorImage={ITC} />
                <VendorsSubCol Animator={Animator} Timeout={{ enter: 830,exit: 1000 }}vendorImage={Kavika} />
                <VendorsSubCol Animator={Animator} Timeout={{ enter: 860,exit: 1000 }}vendorImage={SurfaCoats} />
                <VendorsSubCol Animator={Animator} Timeout={{ enter: 890,exit: 1000 }}vendorImage={Hyundai} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vendors;
