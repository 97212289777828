import React from 'react'
import './css/mobvendors.css'

import UL from "../StyleSVGs/vendorsul.svg";

import Palette from "../StyleSVGs/palette.svg"

import Hyundai from "../Logo/hyundai.svg";
import ITC from "../Logo/itc_limited.svg";
import Kavika from "../Logo/kavika.svg";
import SurfaCoats from "../Logo/surfa_coats.svg";

export default function Mobilevendor() {
    return (
        <div id="vendors" className="mobilevendors">
            <div className="mobilevendors-content">
                <div className="mobilevendors-subtitle">
                    <div className="mobilevendors-title">Our Vendors</div>
                    <div><img src={UL} className="mobilevendors-image" /></div>
                    <p>
                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                    </p>
                    <div><img style={{ margin: "10px 0px" }} src={Palette} className="mobilevendors-image" /></div>
                </div>
                <div className="mobilevendors-grid">
                    <div className="mobilevendors-box">
                        <img src={ITC} className="mobilevendors-box-image" />
                    </div>
                    <div className="mobilevendors-box">
                        <img src={Kavika} className="mobilevendors-box-image" />
                    </div>
                    <div className="mobilevendors-box">
                        <img src={SurfaCoats} className="mobilevendors-box-image" />
                    </div>
                    <div className="mobilevendors-box">
                        <img src={Hyundai} className="mobilevendors-box-image" />
                    </div>
                </div>
            </div>
        </div>
    )
}
