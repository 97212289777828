import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import { MdPersonOutline } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { MdSmartphone } from "react-icons/md";
import { Button } from "../../Button";
import Plus from '../../LogoComponents/PlusInForm'


function ContactUsForm() {
  const [service, setService] = useState([])
  const availability = ["Banners", "Billbooks", "Standees", "Brochures", "Flex-Banners", "LetterHead"]

  useEffect(() => {
    availability.map(ctx => { setService(prevArray => [...prevArray, { data: ctx, value: false }]) });
    console.log(service)
  }, []);

  const handleSubmit = () => {
    e.preventDefault();
    console.log("form submitted");
  };

  const handleSuggestions = (suggestions) => {
    const elementsIndex = service.findIndex(element => element.data == suggestions)
    let newArray = [...service]
    newArray[elementsIndex] = { ...newArray[elementsIndex], value: !newArray[elementsIndex].value }
    setService(newArray)
  }

  return (
    <>
      <div
        className="row contactus__hero-row"
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          width: "100%",
        }}
      >
        <div className="contactus-form-div">
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div className="contactus-form">
              <div className="contactus-form-subcol contact-form-one">
                <div className="input-container">
                  <label className="input-label">Your Name</label>
                  <div className="inputField">
                    <i>
                      <MdPersonOutline fontSize={"24"} />
                    </i>
                    <input type="text" placeholder=" Martin Luther King" />
                  </div>
                </div>
                <div className="input-container">
                  <label className="input-label">Email-Id</label>
                  <div className="inputField">
                    <i>
                      <MdEmail fontSize={"24"} />
                    </i>
                    <input type="text" placeholder=" martin@something.org" />
                  </div>
                </div>
                <div className="input-container">
                  <label className="input-label">Phone </label>
                  <div className="inputField">
                    <i>
                      <MdSmartphone fontSize={"24"} />
                    </i>
                    <input type="text" placeholder="+91 9840291032" />
                  </div>
                </div>
                <div className="services">
                  {!service.length ? <div className="form-suggestion-default">Add from below</div> :
                    <div className="service-input-container">
                      <label className="input-label">Services </label>
                      <div className="services-column">
                        {service.map((content, index) => (
                          <div key={index}>
                            {<Button status={`${content.value}`} type="button" onClick={() => handleSuggestions(`${content.data}`)} buttonSize="btn--inForm" buttonStyle="btn--suggestion">{content.data} <Plus ifActive={content.value}/></Button>}
                          </div>
                        ))}
                      </div>
                    </div>}
                </div>
              </div>
              <div className="contactus-form-subcol contact-form-two">
                <div className="message-area">
                  <label className="input-label">Message </label>
                  <div className="textField">
                    <textarea placeholder="I was wondering about availability and rates.  I need help with the following:"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="contactus-submit">
              <Button buttonStyle="btn--home" buttonSize="btn--small-home">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>

  );
}

export default ContactUsForm;
