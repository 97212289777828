import React from 'react'

export default function PlusInForm(props) {
    const { ifActive } = props

    return (
        <>
            <div className="plus-img">

                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 24 24" xmlSpace="preserve" fill="none">
                    <path className="st0" d="M0,0h24v24H0V0z" />
                    {ifActive ?
                        <path className="st1" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17,13H7v-2h10V13z" fill="#c02424" />
                        : <path className="st1" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17,13h-4v4h-2v-4H7v-2h4V7h2v4h4V13z" fill="#F2CF15" />
                    }
                </svg>
            </div>
        </>
    )
}
