import React from 'react'

import './css/mobcontactus.css'

import { BiMap } from "react-icons/bi";
import { FaPhoneAlt } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";

export default function Mobilecontactus() {
    return (
        <div id="contact-us" className="mobilecontactus">
            <div className="mobilecontactus-content">
                <div className="mobilecontactus-title">How can we help?</div>
                <div className="mobilecontactus-grid">
                    <div className="mobilecontactus-box" onClick={() => {
                        window.open('mailto:helpdesk@artandprint.com', '_blank').focus();
                    }}>
                        <MdMailOutline size={50} color={'#330E61'} />
                        helpdesk@artandprint.com
                    </div>
                    <div className="mobilecontactus-box" onClick={() => {
                        window.open('tel:+918023307476', '_blank').focus();
                    }}>
                        <FaPhoneAlt size={50} color={'#330E61'} />
                        +918023307476
                    </div>
                    <div className="mobilecontactus-box" onClick={() => {
                        window.open('https://goo.gl/maps/J9qBv4LzSvfAviLz6', '_blank').focus();
                    }}>
                        <BiMap size={50} color={'#330E61'} />
                        <div>
                            #10, 15th Cross Rd, Cholourpalya, Pipeline,
                            Vijayanagar, Bengaluru, Karnataka 560023
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
