import React, { useState, useEffect } from "react";
import "../styles/Navbar.css";
import { FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Pager } from "react-bootstrap";

//JS_SVG
import MobileNavLogo from "../LogoComponents/Mobile/MobileNavLogo"
import MobileNavMenu from "../LogoComponents/Mobile/MobileNavMenu"


function Mobilenavbar(props) {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = (value) => {
        setClick(false);
        if (value) {
            window.location.href = value;
        }
    }

    const { LogoCol } = props;

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
        window.addEventListener("resize", showButton);
    }, []);

    return (
        <>
            <IconContext.Provider value={{ color: "#fff" }}>
                <nav>
                    <div style={{ backgroundColor: LogoCol }} className="navbar-new navbar-container container-new">
                        <div className="navbar-inner">
                            <Pager.Item
                                key={1}
                                eventKey={0}
                                className="navbar-logo"
                                onClick={closeMobileMenu}
                            >
                                <MobileNavLogo />
                            </Pager.Item>
                            <div className="menu-icon" onClick={handleClick}>
                                {click ? <FaTimes color={'#4C4107'} /> : <MobileNavMenu />}
                            </div>
                            <ul className={click ? "nav-menu active" : "nav-menu"}>
                                <li className="nav-item">
                                    <div className="nav-links" onClick={() => closeMobileMenu('#home')}>
                                        Home
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className="nav-links" onClick={() => closeMobileMenu('#products')}>
                                        Products
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className="nav-links" onClick={() => closeMobileMenu('#vendors')}>
                                        Vendors
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className="nav-links" onClick={() => closeMobileMenu('#contact-us')}>
                                        Contact Us
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </IconContext.Provider>
        </>
    );
}

export default Mobilenavbar;
