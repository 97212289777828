import React, { useState, useLayoutEffect } from "react";
import "./Home.css";
import { Button } from "../../Button";
import Frame from "../../Icons/frame.svg";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function Home(props) {
  const { handlePageChange } = props
  const [width, height] = useWindowSize();

  return (
    <>
      {" "}
      <div className={"home__hero-section darkBg"} style={{ height: height }}>

        <div
          className="hero_section"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="col-new">
            <div className="home__hero-text-wrapper">
              <h1 className={"heading"}>ART & PRINT</h1>
              <p className={"home__hero-subtitle"}>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
              </p>
              <div>
                <Button onClick={() => handlePageChange(3)} buttonStyle="btn--home" buttonSize="btn--small-home">
                  Get Started
                </Button>
              </div>
            </div>
          </div>
          <div className="col-new">
            <div className="home__hero-img-wrapper">
              <img src={Frame} alt="printing" className="home__hero-img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
