import React from "react";

import "./ContactUs.css";
import ContactUsLeftCol from "./ContactUsLeftCol";
import ContactUsForm from "./ContactUsForm";
import { BiMap } from "react-icons/bi";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function ContactUs() {
  return (
    <>
      {" "}
      <div className={"contactus__hero-section contactus-darkBg"}>
        <div className="container-new">
          <div className="contactus-main">
            <div
              className="row-new contactus__hero-row"
            >
              <div className="contactus-header">How can we help?</div>
            </div>
            <div
              className="row-new contactus__hero-row"
            >
              <div className="contactus-col-new contactus-one ">
                <ContactUsLeftCol
                  icon={
                    <>
                      <BiMap size={50} />
                    </>
                  }
                  data={`#10, 15th Cross Rd, Cholourpalya, Pipeline, Vijayanagar, Bengaluru, Karnataka 560023`}
                  onClick={() => {
                    window.open('https://goo.gl/maps/J9qBv4LzSvfAviLz6', '_blank').focus();
                  }}
                />
                <a
                  href="tel:+918023307476"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  <ContactUsLeftCol
                    icon={
                      <>
                        <FaPhoneAlt size={50} />
                      </>
                    }
                    data={`+918023307476`}
                  />
                </a>
                <ContactUsLeftCol
                  icon={
                    <>
                      <MdEmail size={50} />
                    </>
                  }
                  data={`helpdesk@artandprint.com`}
                  onClick={() => {
                    window.open('mailto:helpdesk@artandprint.com', '_blank').focus();
                  }}
                />
              </div>
              <div className="contactus-col-new contactus-two ">
                <ContactUsForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
