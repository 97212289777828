import React, { useState, useLayoutEffect } from "react";
import "./Products.css";
import Badge from "../../Icons/badge 1.svg";
import Banner from "../../Icons/banner 1.svg";
import Billboard from "../../Icons/billboard 1.svg";
import Brochure from "../../Icons/brochure 1.svg";
import Email from "../../Icons/email 1.svg";
import File from "../../Icons/file 1.svg";
import Invoice from "../../Icons/invoice 1.svg";
import Print from "../../Icons/print 1.svg";
import ProductSubCol from "./ProductSubCol";
import UL from "../../StyleSVGs/whatwedoul.svg";
import Palette from "../../StyleSVGs/palette.svg"

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function Products(props) {
  const { Animator } = props
  const [width, height] = useWindowSize();
  return (
    <>
      {" "}
      <div
        className={"home__hero-section products-darkBg"}
        style={{ height: height }}
      >
        <div className="container-new" style={{ height: height }}>
          <div
            className="row-new home__hero-row"
            style={{
              height: height,
            }}
          >
            <div className="product-col-new one">
              <div className="product__hero-text-wrapper">
                <h1 className={"product-heading"}>What We Do?</h1>
                <img src={UL} />
                <p className={"product__hero-subtitle"}>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet.
                </p>
                <p className={"product__hero-subtitle"}><img src={Palette} /></p>
              </div>
            </div>
            <div className="product-col-new  two">
              <div className="home__hero-img-wrapper">

                <ProductSubCol
                  firstImage={Badge}
                  secondImage={Brochure}
                  firstProductText={"Stickers"}
                  secondProductText={"Brochures"}
                  Animator={Animator}
                  Timeout={{ enter: 800, exit: 1000 }}
                />
                <ProductSubCol
                  firstImage={Banner}
                  secondImage={Billboard}
                  firstProductText={"Standees"}
                  secondProductText={"Banners"}
                  Animator={Animator}
                  Timeout={{ enter: 830, exit: 1000 }}
                />
                <ProductSubCol
                  firstImage={Email}
                  secondImage={File}
                  firstProductText={"Envelopes"}
                  secondProductText={"Letterheads"}
                  Animator={Animator}
                  Timeout={{ enter: 860, exit: 1000 }}
                />
                <ProductSubCol
                  firstImage={Invoice}
                  secondImage={Print}
                  firstProductText={"Bill Books"}
                  secondProductText={"Flex Banners"}
                  Animator={Animator}
                  Timeout={{ enter: 890, exit: 1000 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
