import React, { useState, useLayoutEffect } from "react";
import FullPage from "./FullPage";
import Mobilehome from "./MobileViews/Mobilehome";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

export default function App() {
    const [width, height] = useWindowSize();
    console.log(width, height);

    if (width <= 1125) {
        return (
            <>
                <Mobilehome />
            </>
        )
    }
    if (width >= 1125) {
        return (
            <>
                <FullPage />
            </>
        )
    }
}
