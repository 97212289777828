import React from 'react'
import { Slide } from '@material-ui/core';

import './css/mobproducts.css'

import Badge from "../Icons/badge 1.svg";
import Banner from "../Icons/banner 1.svg";
import Billboard from "../Icons/billboard 1.svg";
import Brochure from "../Icons/brochure 1.svg";
import Email from "../Icons/email 1.svg";
import File from "../Icons/file 1.svg";
import Invoice from "../Icons/invoice 1.svg";
import Print from "../Icons/print 1.svg";
import UL from "../StyleSVGs/whatwedoul.svg";
import Palette from "../StyleSVGs/palette.svg"


export default function Mobileproducts() {
    return (
        <div id="products" className="mobileproducts">
            <div className="mobileproducts-content">
                <div className="mobileproducts-subtitle">
                    <div className="mobileproducts-title">What we do?</div>
                    <div><img src={UL} className="mobileproducts-image" /></div>
                    <p>
                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                    </p>
                    <div><img src={Palette} className="mobileproducts-image" /></div>
                </div>
                <div className="mobileproducts-grid">
                    <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                        <div className="mobileproducts-box">
                            <div>
                                <img src={Billboard} className="mobileproducts-box-image" />
                            </div>
                            <div className="mobileproducts-box-text">Banner</div>
                        </div>
                    </Slide>
                    <Slide direction="left" in={true}>
                        <div className="mobileproducts-box">
                            <div>
                                <img src={Invoice} className="mobileproducts-box-image" />
                            </div>
                            <div className="mobileproducts-box-text">Bill Books</div>
                        </div>
                    </Slide>
                    <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                        <div className="mobileproducts-box">
                            <div>
                                <img src={Banner} className="mobileproducts-box-image" />
                            </div>
                            <div className="mobileproducts-box-text">Standees</div>
                        </div>
                    </Slide>
                    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                        <div className="mobileproducts-box">
                            <div>
                                <img src={Brochure} className="mobileproducts-box-image" />
                            </div>
                            <div className="mobileproducts-box-text">Brochures</div>
                        </div>
                    </Slide>
                    <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                        <div className="mobileproducts-box">
                            <div>
                                <img src={Email} className="mobileproducts-box-image" />
                            </div>
                            <div className="mobileproducts-box-text">Envelopes</div>
                        </div>
                    </Slide>
                    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                        <div className="mobileproducts-box">
                            <div>
                                <img src={Print} className="mobileproducts-box-image" />
                            </div>
                            <div className="mobileproducts-box-text">Flex Banners</div>
                        </div>
                    </Slide>
                    <Slide direction="right" in={true} mountOnEnter unmountOnExit>

                        <div className="mobileproducts-box">
                            <div>
                                <img src={Badge} className="mobileproducts-box-image" />
                            </div>
                            <div className="mobileproducts-box-text">Stickers</div>
                        </div>
                    </Slide>
                    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                        <div className="mobileproducts-box">
                            <div>
                                <img src={File} className="mobileproducts-box-image" />
                            </div>
                            <div className="mobileproducts-box-text">Letterheads</div>
                        </div>
                    </Slide>

                </div>
            </div>
        </div>
    )
}
