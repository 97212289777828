import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import "./styles/Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Pager } from "react-bootstrap";
import NavbarLogo from "./LogoComponents/NavbarLogo"


function Navbar(props) {
  const [click, setClick] = useState(false);

  const { handlePageChange, LogoCol, buttonClass } = props;

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav>
          <div className="navbar-new navbar-container container-new">
            <div className="navbar-inner">
              <Pager.Item
                key={1}
                eventKey={0}
                onSelect={handlePageChange}
                className="navbar-logo"
              >
                <NavbarLogo LogoCode={LogoCol} />
              </Pager.Item>
              <ul className="nav-menu">
                <Pager.Item
                  className="nav-item"
                  key={2}
                  eventKey={1}
                  onSelect={handlePageChange}
                >
                  <div className={`nav-links ${LogoCol}`}>
                    Products
                  </div>
                </Pager.Item>
                <Pager.Item
                  className="nav-item"
                  key={3}
                  eventKey={2}
                  onSelect={handlePageChange}
                >
                  <div className={`nav-links ${LogoCol} `}>
                    Vendors
                  </div>
                </Pager.Item>
                <Pager.Item
                  className="nav-item"
                  key={4}
                  eventKey={3}
                  onSelect={handlePageChange}
                >
                  <div className={`nav-links ${LogoCol}`}>
                    Contact Us
                  </div>
                </Pager.Item>
                <li className="nav-btn">
                  <div className="btn-link">
                    <Button onClick={(e) => {
                      e.preventDefault();
                      window.location.href = 'https://artandprint.co/billing';
                    }} buttonColor={buttonClass}>Sign In</Button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
