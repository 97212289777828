import React from 'react'

export default function MobileNavMenu(props) {
    const { LogoCode } = props

    const COLOR = ["primaryNav", "blueNav", "purpleNav"];

    const checkLogoColor = COLOR.includes(LogoCode) ? LogoCode : COLOR[0];

    return (
        <div className={`${checkLogoColor}`}>
            <svg width="42" height="21" viewBox="0 0 49 25" xmlns="http://www.w3.org/2000/svg">
                <path d="M47 2H2M47 12H16M47 23H27.5" strokeWidth="4" strokeLinecap="round" />
            </svg>
        </div>

    )
}
