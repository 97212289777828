import React, { useState } from 'react'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import { Button } from "../Button";

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Frame from "../Icons/frame.svg";
import './css/mobhome.css'

import Mobilenavbar from './Mobilenavbar'
import Mobileproducts from './Mobileproducts';
import Mobilevendor from './Mobilevendor';
import Mobilecontactus from './Mobilecontactus';


export default function Mobilehome() {
    const [col, setCol] = useState('none');

    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y <= -700) {
            setCol('#392AA3')
        }
        if (currPos.y <= -1700) {
            setCol('#6A1B9A')
        }
        if (currPos.y >= -700) {
            setCol('rgb(106 27 154 / 0%)')
        }

        console.log(currPos.y)
    })

    return (
        <div className="mobileView">
            <Mobilenavbar LogoCol={col} />
            <div id="home" className="mobilehero">
                <div className="mobilehero-content">
                    <div className="mobilehero-image"><img src={Frame} alt="printing" /></div>
                    <div className="mobilehero-subtitle">
                        <div className="mobilehero-title">ART & PRINT</div>
                        <p>
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.Velit officia consequat duis enim velit mollit.Exercitation veniam consequat sunt nostrud amet.
                        </p>
                        <div>
                            <Button onClick={(e) => {
                                e.preventDefault();
                                window.location.href = '#contact-us';
                            }} buttonStyle="btn--home" buttonSize="btn--small-home">
                                Get Started
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="down-arrow">
                    <div>Scroll Down</div>
                    <ArrowDownwardIcon style={{ fontSize: 40 }} />
                </div>
            </div>
            <Mobileproducts />
            <Mobilevendor />
            <Mobilecontactus />
        </div>
    )
}
