import React from "react";
import "./ContactUs.css";

function ContactUsLeftCol({ icon, data, onClick, style }) {
  return (
    <div
      className="row contactus__hero-row"
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div className="contactus-subcol-new" onClick={onClick}>
        <div className="contactus-box" style={style}>
          <div>{icon}</div>
          <div className="contactus-text">{data}</div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsLeftCol;
