import React from "react";
import "./styles/Button.css";

const STYLES = ["btn--primary", "btn--outline", "btn--suggestion", "btn--home"];

const SIZES = [
  "btn--small",
  "btn--form",
  "btn--inForm",
  "btn--medium",
  "btn--large",
  "btn--mobile",
  "btn--wide",
  "btn--small-home",
];

const COLOR = ["primary", "blue", "purple"];

const STATUS = ["true", "false"]

export const Button = ({
  status,
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : null;

  const checkButtonStatus = STATUS.includes(status) ? status : null;


  return (
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor} ${checkButtonStatus}BTN`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};
