import React from "react";
import "./Vendors.css";
import { Slide } from '@material-ui/core';

function VendorSubCol({ vendorImage, Animator,Timeout }) {
  return (
    <Slide direction="left" in={Animator.Slide2} timeout={Timeout}>
      <div
        className="row home__hero-row"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="vendor-subcol-new">
          <div className="vendor-box">
            <img className="vendor-image" src={vendorImage} />
          </div>
        </div>
      </div>
    </Slide>

  );
}

export default VendorSubCol;
